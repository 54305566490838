@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body {
    height: 100vh; } }

body _:-ms-fullscreen,
body :root .ie11up {
  height: 100vh; }

.base-bg {
  background-color: #002855;
  color: #fff; }

.alternate-bg {
  background-color: #0097ce;
  color: #fff; }

select {
  display: initial;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  outline: none;
  height: 3rem;
  font-size: 16px;
  margin: 0 0 8px 0;
  padding: 0;
  box-shadow: none;
  box-sizing: content-box;
  transition: box-shadow 0.3s, border 0.3s;
  -webkit-appearance: none; }
  select:focus {
    outline: none;
    border-bottom: 1px solid !important; }
    select:focus.invalid ~ label {
      color: #f44336; }
    select:focus.valid ~ label {
      color: #4caf50; }
  select::after {
    content: 'arrow_drop_down';
    font-family: 'Material Icons';
    height: 40px;
    position: absolute;
    right: 0;
    top: 6%;
    width: 12%;
    font-size: 24px; }
    @media (min-width: 992px) {
      select::after {
        width: 8%; } }
    @media (min-width: 768px) {
      select::after {
        width: 8%; } }
  select.invalid {
    border-bottom: 1px solid #f44336;
    box-shadow: 0 1px 0 0 #f44336; }
  select.valid {
    border-bottom: 1px solid #4caf50;
    box-shadow: 0 1px 0 0 #4caf50; }

.inlineBlock {
  display: inline-block; }

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh; }

@media (max-width: 922px) {
  .col > .btn,
  .col > .btn-flat {
    width: 100%;
    margin-bottom: 20px; } }

.btn a {
  color: #fff; }

button.btn.inlineBlock {
  width: 100%; }

.dropdownarrow select {
  position: relative; }

.dropdownarrow::before {
  content: 'arrow_drop_down';
  font-family: 'Material Icons';
  height: 40px;
  position: absolute;
  right: 22px;
  top: 6%;
  width: 12%;
  font-size: 24px; }
  @media (min-width: 992px) {
    .dropdownarrow::before {
      width: 8%; } }
  @media (min-width: 768px) {
    .dropdownarrow::before {
      width: 8%; } }

.del-btn {
  background-color: #ad2e2e; }
  .del-btn:hover {
    background-color: #c42e2e; }
  .del-btn:focus {
    background-color: #ad2e2e; }

.waves-effect.waves-side-nav-btn .waves-ripple {
  /* The alpha value allows the text and background color
  of the button to still show through. */
  background-color: #0097ce; }

.sm-tag {
  font-size: 0.75rem;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  background-color: #ddd;
  padding: 3px 6px; }

@media (min-width: 992px) {
  .l-flex {
    display: flex; } }

.disabled {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: 'grayscale(100%)';
  -o-filter: grayscale(100%);
  opacity: 0.4;
  pointer-events: none; }

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateX(0); } }

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateX(0); } }

.animated-from-bottom {
  animation: 0.25s ease-out 0s 1 slideInFromBottom; }

.animated-from-top {
  animation: 0.25s ease-out 0s 1 slideInFromTop; }

.breadcrumb-cluster-icon {
  background-color: #6583a8;
  height: 12px;
  border-radius: 50%; }
